import { getDoc,doc,setDoc,collection, getDocs, query, where, updateDoc,addDoc} from "firebase/firestore";
import { firestore } from "../components/Firebase";
import {toast } from "react-toastify";
const classnames = (...args) => {
  return args.join(" ");
};
const difficultyTable = {
    
  "Beginner": {
    textColor: "text-green-800",
    color: "bg-green-300"
  },
  "Easy": {
    textColor: "text-yellow-800",
    color: "bg-yellow-300"
  },
  "Medium": {
    textColor: "text-orange-800",
    color: "bg-orange-300"
  },
  "Hard": {
    textColor: "text-red-800",
    color: "bg-red-300"
  },
  "Expert": {
    textColor: "text-zinc-800",
    color: "bg-zinc-300"
  }
}
function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
  }));
}

const getUser = async (user) => {
  const q = query(collection(firestore, "users"), where("email", "==", user.email));
  const querySnapshot = await getDocs(q);
  let userInfo;

  // Use for..of loop for proper handling of async/await
  for (const doc of querySnapshot.docs) {
      userInfo = doc.data();
      break; // Assuming you want to get the first matching user only
  }

  return userInfo;
}

const getQuestion = async (aid) => {
  const docRef = doc(firestore, "assignments", aid);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}


const updateSubmission = async (result, submissionId , problem, user, userInfo, code, language, aid) => {
  // Reference to the specific submission document in the 'assignmentSubmissions' collection
  const submissionRef = doc(firestore, "assignmentSubmissions", submissionId);

  try {
      const submissionData = {
          // Fields you want to update
          title: problem.title,
          difficulty: problem.difficulty,
          submitAt: Date.now(),
          submitBy: {id: user.uid, email: user.email, displayName: userInfo.displayName},
          aid: aid,
          comment: "",
          code: code,
          classes: userInfo.classes,
          grade: -1,
          status: result,
          language: language.name
      };

      // Update the document
      await updateDoc(submissionRef, submissionData);
  } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
      console.log(errorCode, errorMessage);
  }
};

const showErrorToast = (msg, timer) => {
  toast.error(msg || `Something went wrong! Please try again.`, {
    position: "bottom-right",
    autoClose: timer ? timer : 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const showSuccessToast = (msg) => {
  toast.success(msg || `Compiled Successfully!`, {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export {updateSubmission, showSuccessToast, showErrorToast, classnames, getQuestion,  getUser, b64EncodeUnicode, difficultyTable};
