import React, { useEffect, useState } from "react";
import CodeEditorWindow from "../components/CodeEditorWindow";
import axios from "axios";
import LanguagesDropdown from "../components/LanguagesDropdown";
import ThemeDropdown from "../components/ThemeDropdown";
import FontSizeDropdown from "../components/FontSizeDropdown";
import { languageOptions } from "../constants/languageOptions";
import { themeOptions } from "../constants/themeOptions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import OutputWindow from "../components/OutputWindow";
import CustomInput from "../components/CustomInput";
import { useSearchParams } from 'react-router-dom';
import Splitter from 'm-react-splitters';
import 'm-react-splitters/lib/splitters.css';
import Submit from "../components/Submit";
import { getDoc,doc,setDoc,collection } from "firebase/firestore";
import { firestore} from '../components/Firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../components/Firebase';
import Header from "../components/Header";
import { showErrorToast, showSuccessToast, classnames, difficultyTable, b64EncodeUnicode } from "../utils/general";
const RAPID_API_URL = "https://judge0-ce.p.rapidapi.com/submissions"
const RAPID_API_HOST = "judge0-ce.p.rapidapi.com"
const RAPID_API_KEY = "fbe7df1e99msh10f296f62348e88p18ba83jsn4f2f578fb950"

const Landing = () => {
  
  const [fontSize, setFontSize] = useState(24);
  const [problem, setProblem] = useState(null);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const inputArea = document.querySelector('.inputarea');
  const [language, setLanguage] = useState(languageOptions[0]);
  const [theme, setTheme] = useState(themeOptions[1]);
  const [submitExpanded, setSubmitExpanded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({pid: -1});
  const pid = searchParams.get('pid');
  const [PythonDefault, setPythonDefault] = useState('');
  const [code, setCode] = useState('');
  const [customInput, setCustomInput] = useState("");
  const [compileOutputDetails, setCompileOutputDetails] = useState(null);
  const [submitOutputDetails, setSubmitOutputDetails] = useState([]);
  const [processing, setProcessing] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const [user, loading, error] = useAuthState(auth);
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    if (!loading && user){ setPageLoading(false)};
  }, [user, loading]);

  const onSelectChange = (sl) => {
    setLanguage(sl);
  };
  const onThemeSelectChange = (t1) => {
    setTheme(t1);
  }
  const onFontSizeSelectChange = (fs) => {
    setFontSize(fs.value);
  };


  useEffect(() => {

    if (inputArea){
      inputArea.addEventListener("keydown", keyPress);
      return () => {
        inputArea.removeEventListener("keydown", keyPress);
      };
    }
  });

  const keyPress = (e) =>{
    if (e.key === "Enter" && e.ctrlKey === true ){
      handleCompile();
      console.log(customInput)
    }
  }

  function handleThemeChange(th) {
    const theme = th;

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next");
  }, []);
  useEffect(() => {
    getQuestion();
  },[]);

  const getQuestion = async () => {
    const docRef = doc(firestore, "problems", pid);
    const docSnap = await getDoc(docRef);
    setProblem(docSnap.data());
    console.log(docSnap.data())
  }


  useEffect(() => {
    if (enterPress && ctrlPress) {
      handleCompile();
    }
  }, [ctrlPress, enterPress]);


  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        setPythonDefault('');
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };

  const handleSubmit = () => {
    setSubmitOutputDetails([]);
    setSubmitting(true);
    
    let form = []
    
    {problem.testcases.map(t => {
      const newItem = {
        language_id: language.id,
        // encode source code in base64
        expected_output: b64EncodeUnicode(t.Output),
        source_code: b64EncodeUnicode(code),
        stdin: b64EncodeUnicode(t.Input)? b64EncodeUnicode(t.Input): null,
      }
      form.push(newItem);
    }
    )}
    console.log(form)
    const formData = {
      submissions: form
    }

    const options = {
      method: "POST",
      url: RAPID_API_URL + '/batch',
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        let token = '';
        {response.data.map(d => {
          token = token + d.token + ',';
        })}

        checkStatus_Submit(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        // get error status
        console.log(error)
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);

          showErrorToast(
            `too many requests`,
            10000
          );
        }
        setSubmitting(false);
        console.log("catch block...", error);
      });
  };

  const handleCompile = () => {
    setCompileOutputDetails("");
    setProcessing(true);
    const formData = {
      language_id: language.id,
      // encode source code in base64
      source_code: b64EncodeUnicode(code),
      stdin: b64EncodeUnicode(customInput),
    };

    const options = {
      method: "POST",
      url: RAPID_API_URL,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "content-type": "application/json",
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log("status", status);
        if (status === 429) {
          console.log("too many requests", status);

          showErrorToast(
            `too many requests`,
            10000
          );
        }
        setProcessing(false);
        console.log("catch block...", error);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: RAPID_API_URL + "/" + token,
      params: { base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 1000);
        return;
      } else {
        setProcessing(false);
        setSubmitting(false);
        setCompileOutputDetails(response.data);
        showSuccessToast(`Compiled Successfully!`);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      setSubmitting(false);
      showErrorToast();
    }
  };

  const checkStatus_Submit = async (token) => {
    const options = {
      method: "GET",
      url: RAPID_API_URL + "/batch",
      params: { tokens: token, base64_encoded: "true", fields: "*" },
      headers: {
        "X-RapidAPI-Host": RAPID_API_HOST,
        "X-RapidAPI-Key": RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let data = response.data.submissions;
      let processing = false
      {data.map(d => {
        if (d.status?.id === 1 || d.status?.id === 2){
          processing = true
        }
      })}

      // Processed - we have a result
      if (processing) {
        // still processing
        setTimeout(() => {
          checkStatus_Submit(token);
        }, 1000);
        return;
      } else {
        setProcessing(false);
        setSubmitting(false);
        setSubmitOutputDetails(response.data.submissions);
        showSuccessToast(`Compiled Successfully!`);
        if (checkSubmitStatus(response.data.submissions)){
          storeSubmission()
        }
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      setSubmitting(false);
      showErrorToast();
    }
  };

  const storeSubmission = async () => {
    const newSubmission = doc(collection(firestore, "submissions"));
    try{
    const submissionData = {
        title: problem.title,
        difficulty: problem.difficulty,
        submitAt: Date.now(),
        submitBy: {id: user.uid, email: user.email},
        id: pid
    };
    await setDoc(newSubmission, submissionData);
    }
    catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
        console.log(errorCode, errorMessage);
    }
  }


  const checkSubmitStatus = (submissions)=>{
    for (let i = 0; i < submissions.length; i++){
      if (submissions[i].status.description !== 'Accepted'){
        return false
      }
    }
    return true
  }

 

  const handleSubmitExpand = () =>{
    if (submitExpanded == true){
      setSubmitExpanded(false)
    }else{
      setSubmitExpanded(true)
    }
  }

  if (pageLoading){ return null};
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showSubmitModal ? (
        <Submit
          setShowModal={setShowSubmitModal}
          handleExpand={handleSubmitExpand}
          testcases={problem.testcases} 
          code = {code}
          handleSubmit = {handleSubmit}
          submitOutputDetails = {submitOutputDetails}
          submitting = {submitting}
        />
        ) : null}
      <Header></Header>
      <Splitter
          position="vertical"
          primaryPaneMaxWidth="40%"
          primaryPaneMinWidth="0%"
          primaryPaneWidth= "20%"
      >    

          <div className="flex flex-col  border-r-2 overflow-y-auto max-h-screen ">
            {problem? <div className="flex p-3 flex-col">
                <div className="rounded-md p-2 mb-2">
                    <div className="flex justify-between mb-4">
                        <h1 className="text-3xl font-bold">
                            Title: {problem.title}
                        </h1>
                    </div>
                    <hr className="mb-2"></hr>
                    <div>
                        <h6 className="text-2xl mb-2">
                          <span className="p-2 text-lg">
                            ID : {pid}
                          </span>
                        </h6>
                    </div>
                    <div>
                        <h6 className="text-2xl mb-2">
                          <span className={difficultyTable[problem.difficulty].color + ' ' + difficultyTable[problem.difficulty].textColor + ' p-1.5 rounded-xl text-lg'}>
                              {problem.difficulty} 
                          </span>
                        </h6>
                    </div>

                </div>
              <div className="p-2">
                <h1 className="text-2xl font-bold">
                  Description
                </h1>
                <div className="p-2">
                  <p className='text-lg p-4 bg-gray-100 rounded p-2'>
                    {problem.description}
                  </p>
                </div>
              </div>
              <div className="rounded-md p-2">
                <h1 className="text-2xl font-bold">
                    Example
                </h1>
                <div className="p-2">
                    <div className="rounded-md p-2 mb-3 bg-gray-100">
                    <div className="p-2">
                        <div className="flex mb-2">
                            <h1 className='text-lg font-bold mr-2 my-0'>Input:</h1>
                        </div>
                        <pre className="whitespace-pre-wrap">
                            <p className="pl-2 m-0 text-sm">
                              {problem.testcases[0].Input}
                            </p>
                        </pre>
                        <pre className="whitespace-pre-wrap">
                            <h1 className='text-lg font-bold m-0'>Output:</h1>
                            <p className="pl-2 m-0 text-sm">
                              {problem.testcases[0].Output}
                            </p>
                        </pre>
                    </div>
                    </div>
                
                
                </div>
              </div>

            </div> : ""}
          </div>
        
          <div className="flex pl-2 pt-4 flex-col">
            <div className = "block w-[100%] px-3 py-1">
              <div className = "flex justify-between" >
                <div className="mr-2 mb-2">
                  <LanguagesDropdown onSelectChange={onSelectChange} />
                </div>
                <div className="flex">
                  <div className="mt-auto mb-auto">
                      <button
                        onClick={handleCompile}
                        disabled={!code || processing}
                        style={{
                          borderLeft: "1px black solid",
                          borderTop: "1px black solid",
                          borderBottom: "1px black solid"
                        }}
                        className={classnames(
                          "bg-green-300 text-gray-800 font-bold py-1 px-3 rounded-tl-lg rounded-bl-lg inline-flex items-center",
                          !code || processing? "opacity-50" : ""
                        )}
                      >
                        {<img className ='w-4 h-4 mt-1 mb-1'src={process.env.PUBLIC_URL  + `/compile.png`}></img>}
                        <span className="ml-2 lg:block hidden">Run</span>  
                      </button>
                    </div>

                    <div className="mt-auto mb-auto">

                      <button
                        disabled={!code}
                        style={{
                          border: "1px black solid",
                        }}
                        data-tooltip-target="tooltip-default" 
                        className={classnames(
                          "bg-green-300 text-gray-800 font-bold py-1 px-3 rounded-tr-lg rounded-br-lg inline-flex items-center",
                          !code || processing? "opacity-50" : ""
                        )}
                        onClick={() => setShowSubmitModal(true)}
                      >
                        Submit <svg  viewBox="0 0 20 20" aria-hidden="true" focusable="false" className='w-4 h-4 mt-1 mb-1 ml-1'>
                          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                          </path>
                        </svg>
                      </button>
                  </div>
                </div>
                <div className="flex">
                  <div className="mr-2 mb-2">
                    <FontSizeDropdown onSelectChange={onFontSizeSelectChange} />
                  </div>
                  <div className="mb-2">
                    <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
                  </div>
                </div>

              </div>
            
              <div className="items-end codeWindow ">
                    <CodeEditorWindow
                      fontSize = {fontSize}
                      defaultValue = {PythonDefault}
                      code={code}
                      onChange={onChange}
                      language={language?.value}
                      theme={theme.value}
                    />
              </div>

              <div className='flex'>

                    <div className="relative w-[30%] mr-3">
                      <CustomInput
                        code = {code}
                        processing={processing}
                        sendData={handleCompile}
                        customInput={customInput}
                        setCustomInput={setCustomInput}
                      />
                    </div>

                    <OutputWindow compileOutputDetails={compileOutputDetails} />

                </div>
            </div>
          </div>
      </Splitter>
  </>
  );
};
export default Landing;
