import {
    Navbar,
    Collapse,
    Typography,
    Button,
    IconButton,
  } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useSignOut } from 'react-firebase-hooks/auth';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './Firebase';
const Header = () =>{
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [signOut, loading, error] = useSignOut(auth);
    const [openNav, setOpenNav] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener(
          "resize",
          () => window.innerWidth >= 960 && setOpenNav(false)
        );
      }, []);
    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {

            console.log(user)
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
  
              setUser(user)
            } else {
              // User is signed out
              // ...
              console.log("user is logged out")
            }
          });
         
      }, [])
    
    const navList = (
        <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
          {user?user.email[0] == 'a' || user.email[0] == 't'?<Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
          >
            <a href="/addProblem" className="flex items-center">
              Problem
            </a>
          </Typography>:"":""}
          {user?user.email[0] == 'a' || user.email[0] == 't'?<Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
          >
            <a href="/addAssignment" className="flex items-center">
              Assignment
            </a>
          </Typography>:"":""}
          {user?user.email[0] == 'a' || user.email[0] == 't'?<Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
          >
            <a href="/classReview" className="flex items-center">
              AssignmentReview 
            </a>
          </Typography>:"":""}

          {user?user.email[0] == 'a'?<Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
          >
            <a href="/signup" className="flex items-center">
              User
            </a>
          </Typography>:"":""}
          {user?user.email[0] == 'a'?<Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal"
          >
            <a href="/enrol" className="flex items-center">
              Enrol
            </a>
          </Typography>:"":""}
        </ul>
      );


    return(
    <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
    <div className="flex items-center justify-between text-blue-gray-900">
      <Typography
        as="a"
        href="/"
        className="cursor-pointer py-1 font-bold text-xl"
      >
        CodeArena
      </Typography>
      <div className="flex items-center gap-4">
        <div className="mr-4 hidden lg:block">{navList}</div>


        {user?<Button variant="gradient" size="sm" fullWidth className="hidden lg:inline-block" onClick={async () => {
      const success = await signOut();
      if (success) {
        alert('You are sign out');
        window.location.reload();
      }
    }}>
        <span>Sign Out</span>
      </Button>
      :
      <Button variant="gradient" size="sm" fullWidth className="hidden lg:inline-block" onClick={async () => {
        navigate("/signin")
      }}>
        <span>Sign In</span>
      </Button>}

        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
    </div>
    <Collapse open={openNav}>
      {navList}
      {user?<Button variant="gradient" size="sm" className="mb-2" onClick={async () => {
      const success = await signOut();
      if (success) {
        alert('You are sign out');
        window.location.reload();
      }
    }}>
        <span>Sign Out</span>
      </Button>
      :
      <Button variant="gradient" size="sm" className="mb-2" onClick={async () => {
        navigate("/signin")
      }}>
        <span>Sign In</span>
      </Button>}
      
    </Collapse>
  </Navbar>)
}

export default Header