import React, {useState, useEffect} from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import {  useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../components/Firebase';
import { doc, setDoc } from "firebase/firestore";
import Header from "../components/Header"
const Signup = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [user, loading, error] = useAuthState(auth);
    const [pageLoading, setPageLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [role, setRole] = useState('teacher');
    const [password, setPassword] = useState('');

    useEffect(() => {
        console.log(user)
		if (!loading && user){ setPageLoading(false)};
	}, [user, loading]);

    if (pageLoading || !user){ return null};
    if (user.email != 'admin@umac.mo'){ return null};
    
    const addUser = () => {
        if (!email || !password || !displayName || !role) {
            return alert("Please fill all fields");
        }
        setUsers(users => [...users, {
            email:email,
            password:password,
            displayName:displayName,
            role:role
        }]);
        setEmail('')
        setPassword('')
        setDisplayName('')
    }
    
    const onSubmit = async (e) => {
        e.preventDefault()
        users.map(async (u, index) => {
            try{
                const newUser =  await createUserWithEmailAndPassword(auth, u.email, u.password);
                if (!newUser) {return};
                const userData = {
                    uid: newUser.user.uid,
                    email: newUser.user.email,
                    displayName: u.displayName,
                    createdAt: Date.now(),
                    updatedAt: Date.now(),
                    role: u.role,
                    classes: [],
                };
                await setDoc(doc(firestore, "users", newUser.user.uid), userData);
                }
                catch (error) {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage);
                    console.log(errorCode, errorMessage);
                }
        })
        alert('sign up successfully')
    }
 
  return (
    <main >
        <Header></Header>        
        <section className="bg-gray-100 w-screen h-[100vh]">
            <div className = "flex">
                <div className="w-[50%] p-12">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light">
                                <thead className="font-medium">
                                    <tr>
                                    <th scope="col" className="px-6 py-4 border-2 border-gray-500">email</th>
                                    <th scope="col" className="px-6 py-4 border-2 border-gray-500">password</th>
                                    <th scope="col" className="px-6 py-4 border-2 border-gray-500">displayName</th>
                                    <th scope="col" className="px-6 py-4 border-2 border-gray-500">role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((u, index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td className="border-2 border-gray-500 whitespace-nowrap px-6 py-4 font-medium">{u.email}</td>
                                                    <td className="border-2 border-gray-500 whitespace-nowrap px-6 py-4">{u.password}</td>
                                                    <td className="border-2 border-gray-500 whitespace-nowrap px-6 py-4">{u.displayName}</td>
                                                    <td className="border-2 border-gray-500 whitespace-nowrap px-6 py-4">{u.role}</td>
                                                    <button className=" whitespace-nowrap px-6 py-4" onClick={() => setUsers(users.filter(item => item.email !== u.email))}>detele</button>
                                                </tr>
                                                

                                            )
                                        })
                                    }
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                    <button className="p-2 bg-green-500 rounded-lg text-white text-xl" onClick={onSubmit}>
                        Sign Up
                    </button>
                </div>
                <div className="w-[50%]  relative h-[100vh]">
                    <div style={{"translate": "-50% -50%"}} className="text-white absolute top-1/2 left-1/2 px-10 py-5 bg-gray-600 rounded w-[90%] max-w-sm">                                                  
                            <h1 className="text-4xl mb-6 font-bold"> Create User </h1>                                                                                        
                            <form>                                                                                            
                                <div className="mb-3">
                                    <label className="text-xl" htmlFor="email-address">
                                        Email address
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <input
                                        className="p-2 rounded text-xl w-full text-black"
                                        type="email"
                                        label="Email address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}  
                                        required                                    
                                        placeholder="Email address"                                
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="text-xl" htmlFor="password">
                                        Password
                                    </label>
                                </div>  
                                <div className="mb-3">
                                    <input
                                        className="p-2 rounded text-xl w-full text-black"
                                        type="password"
                                        label="Create password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} 
                                        required                                 
                                        placeholder="Password"              
                                    />
                                </div>                                           
                                <div className="mb-3">
                                    <label className="text-xl" htmlFor="displayName">
                                        Display Name
                                    </label>
                                </div> 
                                <div className="mb-3">
                                    <input
                                        className="p-2 rounded text-xl w-full text-black"
                                        type="text"
                                        label="Create displayName"
                                        value={displayName}
                                        onChange={(e) => setDisplayName(e.target.value)} 
                                        required                                 
                                        placeholder="displayName"              
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="text-xl" htmlFor="role">
                                        Role
                                    </label>
                                </div> 
                                <div className="mb-6">
                                    <select 
                                        name="role"
                                        className="p-2 rounded text-xl w-full text-black"
                                        required
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)} 
                                    >
                                        <option value="teacher">Teacher</option>
                                        <option value="student">Student</option>
                                    </select>
                                </div>  
                                <div className="mb-4">
                                    <button
                                        type="submit" 
                                        onClick={addUser}  
                                        className="p-2 bg-green-500 rounded-xl"                       
                                    >  
                                        Create user                                
                                    </button>
                                </div>
                                                                            
                            </form>
                        </div>
                    </div>
                </div>
        </section>
    </main>
  )
}
 
export default Signup