import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import Header  from '../components/Header';
import { collection, query, where, getDocs, } from "firebase/firestore";
import { firestore, auth } from '../components/Firebase';
import AssignmentModal from '../components/AssignmentModal'
import {
  Card,
  CardHeader,
  Typography,
  Chip,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";
import { useAuthState } from "react-firebase-hooks/auth";
import Chart from "react-apexcharts";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";


const LearnProgressData = () => {

    const [chartConfig,setChartConfig] = useState({
        type: "pie",
        width: 400,
        height: 400,
        series: [44, 55, 13, 43],
        
        options: {
            labels: ["Easy", "Medium", "Hard", "Not Solved"],
            chart: {
                toolbar: {
                show: false,
                },
            },
            title: {
                show: "",
            },
            dataLabels: {
                enabled: true,
            },
            colors: ["#00897b","#ff8f00", "#d81b60", "#555555"],
            legend: {
                show: true,
            },
            },
      });

    const [user, loading, error] = useAuthState(auth);
    const [pageLoading, setPageLoading] = useState(true);
    const [role, setRole] = useState(null);
    const [userInfo, setUserInfo] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const [personalScore, setPersonalScore] = useState(0);
    const [personalCount, setPersonalCount] = useState(0);
    const [problemsTableRows, setProblemsTableRows] = useState([]);
    const isUpdated = useRef(false);
    const [userList, setUserList] = useState([]);
    const [pages, setPages] = useState(0);
    const [pageNum, setPageNum] = useState(1);   
            

    const [, forceRender] = useState(undefined);

    const forceRenderClick = () => {
        forceRender((prev) => !prev);
    };

    useEffect(() => {
        //console.log(problemsTableRows.length, submissions.length )
        if (problemsTableRows.length > 0 && submissions.length > 0 && !isUpdated.current) {
          const updatedTableRows = problemsTableRows.map(row => ({
            ...row,
            solved: submissions.find(e => e.id === row.id) ? "Solved" : "Not Solved"
          }));
        
          setProblemsTableRows(updatedTableRows);   
          let count = 0;
          let score = 0;
          let solvedEasy = 0;
          let solvedMedium = 0;
          let solvedHard = 0;   
        //   console.log(submissions)
          problemsTableRows.forEach((row) => {       
            // console.log(submissions.find(e => e.id === row.id && e.uid === user.uid))       
            if (submissions.find(e => e.id === row.id && e.uid === user.uid) ){
                count += 1
                
                if (row.difficulty == "Easy"){
                    score += 20
                    solvedEasy += 1
                }                
                else if (row.difficulty == "Medium"){                    
                    score += 40
                    solvedMedium += 1
                }
                else if (row.difficulty == "Hard"){                    
                    score += 60
                    solvedHard += 1
                }  
            }                              
          });
          
          let tempSolvedDiffculty = [solvedEasy,solvedMedium,solvedHard, problemsTableRows.length - solvedEasy - solvedMedium - solvedHard]
          //console.log(tempSolvedDiffculty)
          
          let temp =chartConfig
          temp.series = tempSolvedDiffculty
          setChartConfig(temp)

          setPersonalCount(count)
          setPersonalScore(score)

          isUpdated.current = true;          
        }
        getRankList()
        forceRenderClick()
      }, [problemsTableRows, submissions]);

    const getRankList =async () => {
        let tempList = []
        userList.forEach( (u) => {
            // console.log(problemsTableRows.length > 0 && submissions.length > 0)
            if (problemsTableRows.length > 0 && submissions.length > 0) { 
                let count = 0;
                let score = 0;
                //   console.log(submissions)
                problemsTableRows.forEach((row) => {       
                // console.log(submissions.find(e => e.id === row.id && e.uid === user.uid))       
                if (submissions.find(e => e.id === row.id && e.uid === u.uid) ){
                    count += 1

                    if (row.difficulty == "Easy"){
                        score += 20
                    }                
                    else if (row.difficulty == "Medium"){                    
                        score += 40
                    }
                    else if (row.difficulty == "Hard"){                    
                        score += 60
                    }  
                }                              
                });

                u.score = score
                tempList.push(u)
            }
        });
        
        
        let temp = [...userList].sort((a, b) => b.score - a.score);
        setUserList(temp) 
        setPages(Math.ceil(userList.length/15))
    }

    const getSubmissions = async () =>{
        // get submissions
        const q = query(collection(firestore, "submissions"));
        
        const querySnapshot = await getDocs(q);
        let temp1 = []
        querySnapshot.forEach((doc) => {
            temp1.push({            
                id: doc.data().id, 
                uid: doc.data().submitBy.id,
                date: doc.data().submitAt,
            });
        });       
        //console.log(temp1, submissions)
        let temp12 =  [...temp1].sort((a, b) => {  
            return new Date(b.date) - new Date(a.date);
          });     
        setSubmissions(temp12)      
                 
        

        // // get assignment submissions
        // const q2 = query(collection(firestore, "assignmentSubmissions"));
        
        // const querySnapshot2 = await getDocs(q2);
        // let temp2 = []
        // querySnapshot2.forEach((doc) => {
        //     temp2.push({
        //         id: doc.data().aid, 
        //         uid: doc.data().submitBy.id
        //     });

        // });      
        // setAssignmentSubmissions(temp2)   
             
      }

    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setRole(doc.data().role)
            setUserInfo(doc.data())
        });
    }

    const getUserList = async () => {
        const q = query(collection(firestore, "users"));
        const querySnapshot = await getDocs(q);
        let temp = []
        querySnapshot.forEach((doc) => {
            temp.push({
                id: doc.id,
                uid: doc.data().uid,
                name: doc.data().displayName,
                role: doc.data().role,
                score: 0,
            });
        });        
        setUserList(temp)
    }

    useEffect(() => {
        if(user && role == null){
            getUser()
            getUserList()
        }
        else if(user&& role){
            getSubmissions()
        }
        if (!loading && user){ setPageLoading(false)};
    }, [user, loading, role]);


    const getQuestion = async () => {
        const q = query(collection(firestore, "problems"));

        const querySnapshot = await getDocs(q);

        let temp = []
        querySnapshot.forEach((doc) => {
            let t = {
              id: doc.id,
              title: doc.data().title,
              difficulty: doc.data().difficulty
            }
            temp.push(t);
          });      
          setProblemsTableRows(temp);
    }

    useEffect(()=>{
          getQuestion();
    }, [])

    if (pageLoading){ return null};
    if (!(role == 'teacher' || role == 'admin' || role == 'student')){ return null};

    return (
        <>
            <Header />
            <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300">
                <div className="flex flex-wrap justify-center grid grid-cols-1 content-start mt-0 gap-10 md:grid-cols-2">
                    <Card className=" w-full max-w-[50rem] overflow-hidden mx-20 my-10">
                        <CardHeader variant="gradient" color="indigo" shadow={false}  size="lg" className="p-4 w-100% m-0 justify-center items-center rounded-none">
                            <Typography color="white" fontWeight="bold" className="pl-4 flex  gap-1 text-4xl font-normal">
                                {userInfo.displayName}
                            </Typography>
                        </CardHeader>
                        <CardBody className="p-6 gap-4">
                            {/* <Typography color="black" fontWeight="bold" fontSize="1xl" className="pl-2 flex  text-1xl font-normal">
                                Email: {userInfo.email}
                            </Typography>
                            <Typography color="black" fontWeight="bold" fontSize="1xl" className="pt-3 pl-2 flex  text-1xl font-normal">
                                problem Solved: {personalCount}
                            </Typography>
                            <Typography color="black" fontWeight="bold" fontSize="1xl" className="pt-3 pl-2 flex text-1xl font-normal">
                                Total Scroe: {personalScore}
                            </Typography> */}
                            <table className="mt-5 w-full">
                                <tbody>                                                   
                                    <tr className = {"rounded p-2 "}>  
                                        <td className="p-2 text-xl md:text-2xl font-bold text-left">Email Address:</td>                                                       
                                        <td className="p-2 text-xl md:text-2xl font-bold text-left">{userInfo.email}</td>                                                  
                                    </tr>
                                    <tr className = {"rounded p-2 m-2 "}>  
                                        <td className="p-2 pt-10 text-xl md:text-2xl font-bold text-left">problem Solved: </td>                                                       
                                        <td className="p-2 pt-10 text-xl md:text-2xl font-bold text-left">{personalCount}</td>                                                  
                                    </tr>
                                    <tr className = {"rounded p-2 m-2 "}>  
                                        <td className="p-2 pt-10 text-xl md:text-2xl font-bold text-left">Total Scroe: </td>                                                       
                                        <td className="p-2 pt-10 text-xl md:text-2xl font-bold text-left">{personalScore}</td>                                                  
                                    </tr>
                                </tbody>
                            </table>

                        </CardBody>
                    </Card>
            
                
                    <Card className="w-auto max-w-[50rem] overflow-hidden mx-20 my-10">
                        <CardHeader
                            floated={false}
                            shadow={false}
                            color="transparent"
                            className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
                        >
                            <div className="w-max rounded-lg bg-indigo-500 p-5 text-white">
                            </div>
                            <div>
                            <Typography variant="h6" color="blue-gray">
                                problems solved by difficulty
                            </Typography>
                            </div>
                        </CardHeader>
                        <CardBody className="mt-4 grid place-items-center px-2 ">
                            <Chart {...chartConfig} />
                        </CardBody>
                    </Card>
                </div> 
                <div className="flex flex-wrap justify-center items-center mt-10 gap-10 m-20">
                    <Card className="h-full w-full overflow-hidden  top-0 ">
                        <CardHeader variant="gradient" color="indigo" shadow={false}  size="lg" className="p-4 w-100% m-0 justify-center items-center rounded-none">
                            <Typography color="white" fontWeight="bold" className="pl-4 flex  gap-1 text-4xl font-normal">
                                Rank Data
                            </Typography>
                        </CardHeader>
                        <CardBody className="p-6 gap-4">
                            <div className="flex flex-wrap justify-center grid grid-cols-1 content-start mt-0 gap-10 md:grid-cols-2">
                                <div className="">
                                    <Typography color="black" fontWeight="bold" className="pl-4 flex  gap-1 text-2xl font-normal">
                                        Submission Record
                                    </Typography>
                                    <table className="mt-5 border-collapse w-full">
                                        <thead>
                                            <tr className="bg-gray-300">
                                                <th className="p-2 text-base font-bold text-left">Submit Day</th>
                                                <th className="p-2 text-base font-bold text-left">Title</th>
                                                <th className="p-2 text-base font-bold text-left">difficulty</th>
                                                <th className="p-2 text-base font-bold text-left">point</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                        
                                            {submissions.length>0? (submissions.map((row, index) => {  
                                                const points = {
                                                    "Easy": 20,
                                                    "Medium": 40,
                                                    "Hard": 60,
                                                } 
                                                return(
                                                (problemsTableRows.find(e => e.id === row.id ) && row.uid === user.uid)?(                                                    
                                                    <tr key={index} className = {"border-2 rounded p-2 m-2 "}>                                                        
                                                        <td className="p-2 text-sm">{new Date((row.date)).toLocaleDateString()}</td>  
                                                        <td className="p-2 text-sm">{problemsTableRows.find(e => e.id === row.id ).title}</td>
                                                        <td className="p-2 text-sm">{problemsTableRows.find(e => e.id === row.id ).difficulty}</td>  
                                                        <td className="p-2 text-sm">{points[problemsTableRows.find(e => e.id === row.id ).difficulty]}</td>                                                 
                                                    </tr>
                                                ):null
                                            )})):null}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="">
                                    <Typography color="black" fontWeight="bold" className="pl-4 flex  gap-1 text-2xl font-normal">
                                        Ranking
                                    </Typography>
                                    <table className="mt-5 border-collapse w-full">
                                        <thead>
                                            <tr className="bg-gray-300">
                                                <th className="p-2 text-base font-bold text-left">Rank</th>
                                                <th className="p-2 text-base font-bold text-left">Name</th>
                                                <th className="p-2 text-base font-bold text-left">Point</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                        
                                            {userList.length>0? (userList.map((row, index) => {                                                                  

                                                if (index < 15*pageNum && index >= 15*(pageNum-1)){
                                                    return(
                                                        <tr key={index} className = {"border-2 rounded p-2 m-2 "}>                                                        
                                                            <td className="p-2 text-sm">{index+1}</td>  
                                                            <td className="p-2 text-sm">{row.name}</td>
                                                            <td className="p-2 text-sm">{row.score}</td>                                                   
                                                        </tr>
                                                    )
                                                }
                                                else{
                                                    return null
                                                }
                                            })):null}
                                        </tbody>
                                    </table>                                    
                                </div>                                
                            </div>
                            <div className="flex flex-wrap justify-center grid grid-cols-1 content-start mt-5 gap-10 md:grid-cols-2">
                                    <div></div>
                                    <div className="flex flex-wrap justify-center grid grid-cols-1 content-start mt-5 gap-10 md:grid-cols-2" >
                                            <button
                                                className="hover:scale-105 rounded-lg bg-green-500 text-white p-5 hover:bg-green-600 focus:outline-none"  
                                                onClick={() => {
                                                    if(pageNum>1){
                                                        setPageNum(pageNum-1)
                                                    }
                                                    else{
                                                        setPageNum(1)
                                                    }
                                                }}                     
                                            >  
                                                prev                              
                                            </button>
                                            <button 
                                                className="hover:scale-105 rounded-lg bg-green-500 text-white p-5 hover:bg-green-600 focus:outline-none" 
                                                onClick={() => {
                                                    if(pageNum<pages){
                                                        setPageNum(pageNum+1)
                                                    }
                                                    else{
                                                        setPageNum(pages)
                                                    }
                                                }}                        
                                            >  
                                                next                             
                                            </button>
                                    </div>                                     
                                 </div>
                        </CardBody>
                    </Card>
                </div> 
                
            </div>    
        </>
    );

}

export default LearnProgressData;