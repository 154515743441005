import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import AddProblem from "./pages/AddProblem";
import AddAssignment from "./pages/AddAssignment";
import Assignment from "./pages/Assignment";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ClassReview from "./pages/ClassReview";
import Enrol from "./pages/Enrol";
import AddUserCSV from "./pages/AddUserCSV";
import LearnProgressData from "./pages/LearningProgressData";
function App() {
  return (
    <Routes>
        <Route path="/" element = {<Home />} />
        <Route path="/problems" element = {<Landing />} />
        <Route path="/assignments" element = {<Assignment />} />
        <Route path="/addProblem" element = {<AddProblem />} />
        <Route path="/addAssignment" element = {<AddAssignment />} />
        <Route path="/enrol" element = {<Enrol />} />
        <Route path="/signup" element = {<SignUp />} />
        <Route path="/signin" element = {<SignIn />} />
        <Route path="/classReview" element = {<ClassReview />} />
        <Route path="/csv" element = {<AddUserCSV />} />
        <Route path="/leaningData" element = {<LearnProgressData />} />
    </Routes>
  );
}

export default App;