import { initializeApp, getApp, getApps} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "fyp-71277.firebaseapp.com",
  projectId: "fyp-71277",
  storageBucket: "fyp-71277.appspot.com",
  messagingSenderId: "967416354878",
  appId: "1:967416354878:web:6b098c4d104fbb47f4596f",
  measurementId: "G-ZGL44F0GN1"
};

// Initialize Firebase
const app = !getApps.length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore, app};