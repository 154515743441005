import Header  from '../components/Header';
import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs,  doc, setDoc, updateDoc, arrayUnion} from "firebase/firestore";
import { firestore, auth } from '../components/Firebase';
import { useAuthState } from "react-firebase-hooks/auth";
const Enrol = () => {
    const [user, loading, error] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [classes, setClasses] = useState([]);
    const [name, setName] = useState(null);
    const [year, setYear] = useState(null);
    const [instructor, setInstructor] = useState(null);
    const [teachers, setTeachers] = useState([]);
    const [students, setStudents] = useState([]);
    const [classSelected, setClassSelected] = useState(null);
    const [studentEnroll, setStudentEnroll] = useState(null);
    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUserInfo(doc.data())
        });
    }

    const getClasses = async () => {
        const q = query(collection(firestore, "classes"));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
            let o = {
                id: doc.id,
                name: doc.data().name,
                instructorID: doc.data().instructorID,
                year: doc.data().year
            }
          temp.push(o);
        });      
        setClasses(temp)
    }

    const getTeachers = async () => {
        const q = query(collection(firestore, "users"), where('role', '==', 'teacher'));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });      
        setTeachers(temp)
    }

    const getStudents = async () => {
        const q = query(collection(firestore, "users"), where('role', '==', 'student'));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });      
        setStudents(temp)
    }

    const update = async () => {
        const d = doc(firestore, "classes", classSelected.id);
        await updateDoc(d, {
            instructorID: classSelected.instructorID,
        });
        alert("Success");
    }
    const unrollStudent = async () => {
        const d = doc(firestore, "users", studentEnroll);
        await updateDoc(d, {
            classes: arrayUnion(classSelected.id)
        });

        const temp = students.map((item, i) => {
            if (item.uid == studentEnroll) {
              return { ...item, classes: [...item.classes, classSelected.id] };
            } else {
              return item;
            }
          })
        setStudents(temp)
    }

    const handleSubmit = async () => {
        const newClass = doc(collection(firestore, "classes"));
        try{
        const classData = {
            name: name,
            instructorID: instructor,
            year: year,
        };
        await setDoc(newClass, classData);
        }
        catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
            console.log(errorCode, errorMessage);
        }

    }

    useEffect(() => {
      if (!loading && user){ setPageLoading(false)};
      if(user){
        getUser();
        getClasses();
        getTeachers();
        getStudents();
      }
    }, [user, loading]);

    if(pageLoading || !userInfo){return null}
    if(userInfo.role != 'admin'){return null;}
    return(
    <>  
        <Header></Header>
        <div className="p-10 flex">
            <div>
                <div className="text-white px-10 py-5 bg-gray-600 rounded w-fit my-10">   
                    <h1 className="text-4xl mb-6 font-bold"> Create Class </h1>                       
                            <form>                                                                                            
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="title">
                                            Name 
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="text"
                                            label="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}  
                                            required                                    
                                            placeholder="Name"                                
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                            year
                                        </label>
                                    </div>  
                                    <div className="mb-3">
                                        <input
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="number"
                                            label="Year"
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)} 
                                            required                                 
                                            placeholder="Year"              
                                        />
                                    </div>  
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="Classes">
                                            Teacher
                                        </label>
                                    </div>  
                                    <div className="mb-6">
                                        <select 
                                            name="teachers"
                                            className="rounded border-2 border-black text-black"
                                            required
                                            value={instructor}
                                            onChange={(e) => setInstructor(e.target.value)}
                                        >
                                            {
                                                teachers.map((t, index)=>{
                                                    return(
                                                    <option key={index} value={t.uid}>{t.displayName}</option>
                                                )
                                                })
                                            }
                                        </select>
                                    </div>  
                                    <div className="mb-4">
                                        <button
                                            type="submit" 
                                            onClick={handleSubmit}  
                                            className="p-2 bg-green-500 rounded-xl"                       
                                        >  
                                            Submit                              
                                        </button>
                                    </div>                                                                    
                        </form>   
                    </div>

            </div>
            <div className="w-[40%] p-10">
                    <h2 className="text-3xl">
                        Class
                    </h2>
                    <div className="flex flex-wrap">
                        {
                            classes.map((c, index)=>{
                                return(
                            <div key={index} className="hover:bg-gray-100 hover:scale-110 border-2 rounded p-2 m-2 cursor-pointer" onClick = {() => {setClassSelected(c)}}>
                                    <div className="font-bold ">
                                        {c.name}
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    <hr></hr>
                    {
                        classSelected?<div className="border-2 rounded p-5 m-5">
                            <label className="text-xl font-bold">
                                Class
                            </label>
                            <div>
                                {classSelected.name}
                            </div>
                            <label className="text-xl font-bold">
                                Year:
                            </label>
                            <div>
                                {classSelected.year}
                            </div>
                            <label className="text-xl font-bold block">
                                Instructor:
                            </label>
                            <select 
                                name="classes"
                                className="m-3 p-2 rounded border-2 border-black"
                                required
                                defaultValue={classSelected.instructorID}
                                value={classSelected.instructorID}
                                onChange={(e) => setClassSelected(
                                    {
                                        name: classSelected.name,
                                        year: classSelected.year,
                                        instructorID: e.target.value,
                                        id: classSelected.id 
                                    }
                                )}
                            >
                                {
                                    teachers.map((t, index)=>{
                                        return(
                                            <option key={index} value={t.uid}>{t.displayName}</option>
                                    )
                                    })
                                }
                            </select>
                            <button onClick = {() => {update()}} className="border-2 rounded-lg bg-green-100 p-1">
                                Update
                            </button>
                            <label className="text-xl font-bold block">
                                Students:
                            </label>
                            <div className="flex flex-wrap">
                                {
                                    students.filter((s) => s.classes.includes(classSelected.id)).map((s, index)=>{
                                        return(
                                            <div key={index} className="border-2 rounded p-2 m-2">
                                                <div className="font-bold">
                                                    {s.displayName}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <select 
                                name="classes"
                                className="m-3 p-2 rounded border-2 border-black"
                                required
                                defaultValue={""}
                                value={studentEnroll}
                                onChange={(e) => 
                                    setStudentEnroll(e.target.value)
                                }
                            >
                                <option key={-1} value={""}></option>
                                {
                                    students.filter((s) => !(s.classes.includes(classSelected.id))).map((s, index)=>{
                                        return(
                                        <option key={index} value={s.uid}>{s.displayName}</option>
                                    )
                                    })
                                }
                            </select>
                            <button onClick = {() => {unrollStudent()}} className="border-2 rounded-lg bg-green-100 p-1">
                                Add
                            </button>

                        </div>:""
                }
            </div>
        </div>
    </>
    )
}

export default Enrol;