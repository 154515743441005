import React, {useState, useEffect} from 'react';
import { collection, query, where, getDocs, getDoc} from "firebase/firestore";
import { auth, firestore} from '../components/Firebase';
import Header from '../components/Header'
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore";
const AddAssignment = () => {
    const [user, loading, error] = useAuthState(auth);
    const [classes, setClasses] = useState([]);
    const [assignmentClasses, setAssignmentClasses] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [role, setRole] = useState(null);
    const [title, setTitle] = useState('');
    const [dueDay, setDueDay] = useState('');
    const [description, setDescription] = useState('');
    const [difficulty, setDifficulty] = useState('Easy');
    const [testcases, setTestcases] = useState([]);

    const [classInfo, setClassInfo] = useState("");
    const [assignments, setAssignments] = useState([]);
    const [assignmentID, setAssignmentID] = useState(null);
    const [pageStatus, setPageStatus] = useState("add")

    const getClasses = async () => {
        const q = query(collection(firestore, "classes"));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
            let o = {
                id: doc.id,
                name: doc.data().name
            }
          temp.push(o);
        });      
        setClasses(temp)
    }

    const getAssignments = async (c) => {
        console.log(c)
        const q = query(collection(firestore, "assignments"), where('classes', 'array-contains', c));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
            let o = {
                id: doc.id,
                title: doc.data().title,
                description: doc.data().description,
                dueDay: doc.data().dueDay.split('T')[0],
            }
          temp.push(o);
        });      
        setAssignments(temp)
    }

    const getAssignmentSubmissions = async (id) => {
        console.log(id)
        const q = await getDoc(doc(firestore, "assignments", id));

        setTitle(q.data().title);
        setDueDay(q.data().dueDay);
        setDescription(q.data().description);
        setDifficulty(q.data().difficulty);

        setTestcases([]);
        await q.data().testcases.forEach((t) =>{
            setTestcases(
                ts => [...ts, {
                    Input: t.Input,
                    Output: t.Output,
                    Visible: t.Visible,
                }]
            )
        });

        setAssignmentClasses([]);
        await q.data().classes.forEach((c) =>     {
            setAssignmentClasses(
                ac => [...ac, c]
            )            
        });
    }

    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setRole(doc.data().role)
        });
    }
    const addTestcase = () => {
        setTestcases(
            ts => [...ts, {
                Input: '',
                Output: '',
                Visible: 'false',
            }]
        )
    }
    const addClass = () => {
        setAssignmentClasses(
            ac => [...ac, classes[0].id]
        )
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(pageStatus == "add")
        {
            const newAssignment = doc(collection(firestore, "assignments"));
            try{
            const assignmentData = {
                title: title,
                description: description,
                difficulty: difficulty,
                testcases: testcases,
                classes: assignmentClasses,
                createdAt: Date.now(),
                updatedAt: Date.now(),
                dueDay: dueDay
            };
            await setDoc(newAssignment, assignmentData);
            alert("SetSuccess");
            }
            catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
                console.log(errorCode, errorMessage);
            }
        }    
        else if (pageStatus == "edit") {
            const updatedAssignment = doc(firestore, "assignments", assignmentID);            
            console.log(updatedAssignment)
            try{
                const assignmentData = {
                    title: title,
                    description: description,
                    difficulty: difficulty,
                    testcases: testcases,
                    classes: assignmentClasses,
                    updatedAt: Date.now(),
                    dueDay: dueDay
                };
                await updateDoc(updatedAssignment, assignmentData);
                alert("updateSuccess");
                }
                catch (error) {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage);
                    console.log(errorCode, errorMessage);
            }
        } 
    }

    const getClass = async () => {
        const q = query(collection(firestore, "classes"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setClasses(
                c => [...c, {
                    id:doc.id,
                    name:doc.data().name
                }]
            )
        });
    }

    const [, forceRender] = useState(undefined);

    const handleClick = () => {
        forceRender((prev) => !prev);
    };

    useEffect(() => {
        getClass()
	}, []);

    useEffect(() => {
        if(user && role == null){
            getUser()
        }
		if (!loading && user){ setPageLoading(false)};
	}, [user, loading]);

	if (pageLoading){ return null};
    if (!(role == 'teacher' || role == 'admin')){ return null};
    

 
    return(
        <>
            <Header></Header>
            <main >
                <div  className="flex">
                    <div className="py-20 p-5 w-[50%] md:w-[30%]">
                        <button
                            onClick={() => {
                                setPageStatus("add");
                                setTitle('');
                                setDueDay('');
                                setDescription('');
                                setDifficulty('Easy');
                                setTestcases([]);
                                setAssignmentClasses([]);
                            }}
                            className="hover:scale-105 rounded-lg bg-green-500 text-white p-1 hover:bg-green-600 focus:outline-none"
                        >
                            Add New Assignment
                        </button>
                        <label className="pt-10 block text-xl font-bold mb-2">
                            Class
                        </label>
                        <select
                            name="classes"
                            className="m-3 p-2 rounded border-2 border-black w-full"
                            required
                            value={classInfo}
                            onChange={(e) => {
                                setClassInfo(e.target.value);
                                getAssignments(e.target.value);
                            }}
                        >
                            <option key={-1} value={""}></option>
                            {classes.map((c, index) => (
                                <option key={index} value={c.id}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                        <hr className="my-5"></hr>
                        <h2 className="text-3xl mb-3">Assignment</h2>
                        <div>
                            <table className="border-collapse w-full">
                                <thead>
                                    <tr className="bg-gray-300">
                                        <th className="p-2 text-base font-bold text-left">Title</th>
                                        <th className="p-2 text-base font-bold text-left">Due Day</th>
                                        <th className="p-2 text-base font-bold text-left">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assignments.map((a, index) => (
                                        <tr key={index} className = {"border-2 rounded p-2 m-2 " + (a.id == assignmentID?"bg-gray-500":"")}>
                                            <td className="p-2 text-sm">{a.title}</td>
                                            <td className="p-2 text-sm">{a.dueDay}</td>
                                            <td className="p-2">
                                                <button
                                                    onClick={() => {
                                                        getAssignmentSubmissions(a.id);
                                                        setAssignmentID(a.id);
                                                        setPageStatus("edit");
                                                    }}
                                                    className="hover:scale-105 rounded-lg bg-green-500 text-white p-1 hover:bg-green-600 focus:outline-none"
                                                >
                                                    Edit Assignment
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className=" w-[45%] right-0 top-20  md:w-[65%] md:top-0 md:right-0">
                        <div className="text-white px-10 py-5 bg-gray-600 rounded w-[95%] mx-auto my-10">                                            
                            <h1 className="text-4xl mb-6 font-bold"> {pageStatus == "add" ? "Create" : "Edit"} Assignment </h1>                       
                            <form>                                                                                            
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="title">
                                            Title
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="text"
                                            label="Title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}  
                                            required                                    
                                            placeholder="Title"                                
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="dueDay">
                                            Due day
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="datetime-local"
                                            label="dueDay"
                                            value={dueDay}
                                            onChange={(e) => setDueDay(e.target.value)}  
                                            required                                    
                                            placeholder="dueDay"                                
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                            Description
                                        </label>
                                    </div>  
                                    <div className="mb-3">
                                        <textarea
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="text"
                                            label="Description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)} 
                                            required                                 
                                            placeholder="Description"              
                                        />
                                    </div>  
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                            Difficulty
                                        </label>
                                    </div>  
                                    <div className="mb-6">
                                        <select 
                                            name="difficulty"
                                            className="p-2 rounded text-xl w-full text-black"
                                            required
                                            value={difficulty}
                                            onChange={(e) => setDifficulty(e.target.value)} 
                                        >
                                            <option value="Easy">Easy</option>
                                            <option value="Medium">Medium</option>
                                            <option value="Hard">Hard</option>
                                        </select>
                                    </div>  
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                        Testcases
                                        </label>
                                    </div>  
                                    {                
                                        testcases.map((t, index) =>{
                                            return(                                                
                                                <div key={index} className=''>
                                                    <div className='grid grid-cols-2 gap-4 content-between'>
                                                        <div>
                                                            <label className="text-xl" htmlFor="description">
                                                                Testcase{index + 1}
                                                            </label>
                                                        </ div>
                                                        <div className='text-right right-0 text-gray-400'>
                                                            <button 
                                                                type="button" 
                                                                onClick={() => {
                                                                    let temp = testcases
                                                                    temp.splice(index, 1)
                                                                    setTestcases(temp)
                                                                    handleClick();
                                                                }}
                                                                className="text-right"
                                                            > 
                                                            -Delete</button>
                                                        </div>                                                        
                                                    </div>  
                                                    <div className="mb-3">
                                                        <label className="text-lg" htmlFor="description">
                                                            Input:
                                                        </label>
                                                    </div> 
                                                    <textarea
                                                        className="p-2 rounded text-xl w-full text-black"
                                                        type="text"
                                                        label=""
                                                        value={testcases[index].Input}
                                                        onChange={(e) => {                                                            
                                                            let temp = testcases
                                                            temp[index].Input = e.target.value
                                                            setTestcases(temp);
                                                            handleClick();
                                                        }
                                                    } 
                                                        required                                 
                                                        placeholder="Input"              
                                                    />
                                                    <div className="mb-1">
                                                        <label className="text-lg" htmlFor="output">
                                                            Output:
                                                        </label>
                                                    </div> 
                                                    <textarea
                                                        className="p-2 rounded text-xl w-full text-black"
                                                        type="text"
                                                        label=""
                                                        value={testcases[index].Output}
                                                        onChange={(e) => {
                                                                let temp = testcases
                                                                temp[index].Output = e.target.value
                                                                handleClick();
                                                                setTestcases(temp)
                                                            }
                                                        }
                                                        required                                 
                                                        placeholder="Output"              
                                                    />
                                                    <div className="mb-1">
                                                        <label className="text-lg" htmlFor="visible">
                                                            Visible:
                                                        </label>
                                                    </div> 
                                                    <div className="mb-3">
                                                        <select 
                                                            name="visible"
                                                            className="p-2 rounded text-xl w-full text-black"
                                                            value={testcases[index].Visible}
                                                            onChange={(e) =>  {
                                                                let temp = testcases
                                                                temp[index].Visible = e.target.value
                                                                handleClick();
                                                                setTestcases(temp)
                                                            }}
                                                        >
                                                            <option value="false">false</option>
                                                            <option value="true">true</option>
                                                        </select>
                                                    </div>  
                                                    <hr className="my-5"></hr>
                                                </div>
                                                
                                            )
                                        })
                                    }
                                    <div className="text-center text-2xl">
                                        <button type="button" onClick={addTestcase}> Add TestCase +</button>
                                    </div>

                                    <div className='grid grid-cols-2 gap-4 content-between'>
                                        <div className='mb-4'>
                                            <label className="text-3xl" htmlFor="description">
                                                Classes
                                            </label>
                                        </ div>
                                        <div className='text-right right-0 text-gray-400'>
                                            <button 
                                                type="button" 
                                                onClick={() => {
                                                    let temp = assignmentClasses
                                                    temp.splice(assignmentClasses.length - 1, 1)
                                                    setAssignmentClasses(temp)
                                                    handleClick();
                                                }}
                                                className="text-right"
                                            > 
                                            -Delete</button>
                                        </div>                                                        
                                    </div>  
                                    {
                                        assignmentClasses.map((t, index) =>{
                                            return(
                                                <div key={index}>
                                                    <div className="mb-3">
                                                        <select 
                                                            name="classes"
                                                            className="p-2 rounded text-xl w-full text-black"
                                                            value={assignmentClasses[index]}
                                                            onChange={(e) =>  {
                                                                assignmentClasses[index] = e.target.value
                                                                handleClick();
                                                            }} 
                                                        >
                                                            {
                                                                classes.map((c, index) => {
                                                                    return(
                                                                            <option key={index} value={c.id}>{c.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>  
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="text-center text-2xl">
                                        <button type="button" onClick={addClass}> Add Class +</button>
                                    </div>


                                    <div className="mb-4">
                                        <button
                                            type="submit" 
                                            onClick={handleSubmit}  
                                            className="p-2 bg-green-500 rounded-xl"                       
                                        >  
                                            Submit                              
                                        </button>
                                    </div>                                                                    
                                </form>                         
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
 
export default AddAssignment;