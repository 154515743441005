import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../components/Firebase';
import Header from '../components/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

const Signin = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [email, setEmail] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (user) navigate('/');
        if (!loading && !user) setPageLoading(false);
    }, [user, loading]);

    if (pageLoading) return null;

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate('/');
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setErrorMessage(errorCode); 
            });
    };

    return (
        <>
            <Header />
            <main>
                <section className="bg-gray-100 relative w-screen h-[100vh]">
                    <div style={{ translate: '-50% -50%' }} className="text-white absolute top-1/3 left-1/2 px-10 py-5 bg-gray-600 rounded w-[50%] max-w-sm">
                        <h1 className="text-4xl mb-6 font-bold"> Sign In </h1>

                        <form>
                            <div className="mb-3">
                                <label className="text-xl" htmlFor="email-address">
                                    Email address
                                </label>
                            </div>
                            <div className="mb-4">
                                <input
                                    id="email-address"
                                    className="p-2 rounded text-xl w-full text-black"
                                    name="email"
                                    type="email"
                                    required
                                    placeholder="Email address "
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mb-3">
                                <label className="text-xl" htmlFor="password">
                                    Password
                                </label>
                            </div>
                            <div className="mb-6">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="p-2 rounded text-xl w-full text-black"
                                    required
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div className="mb-4">
                                <button onClick={onLogin} className="p-2 bg-green-500 rounded-xl">
                                    Login
                                </button>
                            </div>

                            {errorMessage && ( 
                                <div className="text-red-400 text-xl">{errorMessage}</div>
                            )}
                        </form>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Signin;
