import React, {useState, useEffect} from 'react';
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, firestore} from '../components/Firebase';
import Header from '../components/Header'
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
const AddProblem = () => {
    const [user, loading, error] = useAuthState(auth);
    const [pageLoading, setPageLoading] = useState(true);
    const [role, setRole] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [difficulty, setDifficulty] = useState('Easy');
    const [testcases, setTestcases] = useState([]);

    const [problems, setProblems] = useState();
    const [problemID, setProblemID] = useState(null);
    const [pageStatus, setPageStatus] = useState("add")
    const [assignmentID, setAssignmentID] = useState(null);

    const getProblems = async () => {
        const q = query(collection(firestore, "problems"));

        const querySnapshot = await getDocs(q);  
        let temp = []
        querySnapshot.forEach((doc) => {
            let o = {
                id: doc.id,
                title: doc.data().title
            }
          temp.push(o);
        });      
        setProblems(temp)
    }

    getProblems();

    const getProblemsInfo = async (id) => {
        console.log(id)
        const q = await getDoc(doc(firestore, "problems", id));

        setTitle(q.data().title);
        setDescription(q.data().description);
        setDifficulty(q.data().difficulty);

        setTestcases([]);
        await q.data().testcases.forEach((t) =>{
            setTestcases(
                ts => [...ts, {
                    Input: t.Input,
                    Output: t.Output,
                    Visible: t.Visible,
                }]
            )
        });
    }

    const getUser = async () => {
        const q = query(collection(firestore, "users"), where("email", "==", user.email));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setRole(doc.data().role)
            console.log(doc.data().role)
          });
    }
    const addTestcase = () => {
        setTestcases(
            ts => [...ts, {
                Input: '',
                Output: '',
                Visible: 'false',
            }]
        )
        console.log(testcases)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(pageStatus == "add")
        {
            const newProblem = doc(collection(firestore, "problems"));
            try{
            const problemData = {
                title: title,
                description: description,
                difficulty: difficulty,
                testcases: testcases,
                createdAt: Date.now(),
                updatedAt: Date.now()
            };
            await setDoc(newProblem, problemData);
            alert("Success");
            }
            catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
                console.log(errorCode, errorMessage);
            }
        }
        else if (pageStatus == "edit") {
            const updatedAssignment = doc(firestore, "problems", problemID);            
            console.log(updatedAssignment)
            try{
                const assignmentData = {
                    title: title,
                    description: description,
                    difficulty: difficulty,
                    testcases: testcases,
                    updatedAt: Date.now(),
                };
                await updateDoc(updatedAssignment, assignmentData);
                alert("updateSuccess");
                }
                catch (error) {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage);
                    console.log(errorCode, errorMessage);
            }
        }

    }

    const [, forceRender] = useState(undefined);

    const handleClick = () => {
        forceRender((prev) => !prev);
    };

    useEffect(() => {
        if(user && role == null){
            getUser()
        }
		if (!loading && user){ setPageLoading(false)};
	}, [user, loading]);

	if (pageLoading){ return null};
    if (!(role == 'teacher' || role == 'admin')){ return null};    
    

 
    return(
        <>
            <Header></Header>
            <main >
                <div className="flex">
                    <div className="py-20 p-5 w-[50%] md:w-[30%]">
                        <button
                            onClick={() => {
                                setPageStatus("add");
                                setTitle('');
                                setDescription('');
                                setDifficulty('Easy');
                                setTestcases([]);
                            }}
                            className="hover:scale-105 rounded-lg bg-green-500 text-white p-1 hover:bg-green-600 focus:outline-none"
                        >
                            Add New Problem
                        </button>                        
                        <hr className="my-5"></hr>
                        <h2 className="text-3xl mb-3">Problems</h2>
                        <div>
                            <table className="border-collapse w-full">
                                <thead>
                                    <tr className="bg-gray-300">
                                        <th className="p-2 text-base font-bold text-left">Title</th>
                                        <th className="p-2 text-base font-bold text-left">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {problems.map((a, index) => (
                                        <tr key={index} className = {"border-2 rounded p-2 m-2 " + (a.id == problemID?"bg-gray-500":"")}>
                                            <td className="p-2 text-sm">{a.title}</td>
                                            <td className="p-2">
                                                <button
                                                    onClick={() => {
                                                        getProblemsInfo(a.id);
                                                        setProblemID(a.id);
                                                        setPageStatus("edit");
                                                        
                                                    }}
                                                    className="hover:scale-105 rounded-lg bg-green-500 text-white p-1 hover:bg-green-600 focus:outline-none"
                                                >
                                                    Edit problem
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className=" w-[45%] right-0 top-20  md:w-[65%] md:top-0 md:right-0">
                        <div className="text-white px-10 py-5 bg-gray-600 rounded w-[95%] mx-auto my-10">                                            
                            <h1 className="text-4xl mb-6 font-bold"> {pageStatus == "add" ? "Create" : "Edit"} Problem </h1>                       
                            <form>                                                                                            
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="title">
                                            Title
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="text"
                                            label="Title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}  
                                            required                                    
                                            placeholder="Title"                                
                                        />
                                    </div>                                    
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                            Description
                                        </label>
                                    </div>  
                                    <div className="mb-3">
                                        <textarea
                                            className="p-2 rounded text-xl w-full text-black"
                                            type="text"
                                            label="Description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)} 
                                            required                                 
                                            placeholder="Description"              
                                        />
                                    </div>  
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                            Difficulty
                                        </label>
                                    </div>  
                                    <div className="mb-6">
                                        <select 
                                            name="difficulty"
                                            className="p-2 rounded text-xl w-full text-black"
                                            required
                                            value={difficulty}
                                            onChange={(e) => setDifficulty(e.target.value)} 
                                        >
                                            <option value="Easy">Easy</option>
                                            <option value="Medium">Medium</option>
                                            <option value="Hard">Hard</option>
                                        </select>
                                    </div>  
                                    <div className="mb-3">
                                        <label className="text-3xl" htmlFor="description">
                                        Testcases
                                        </label>
                                    </div>  
                                    {                
                                        testcases.map((t, index) =>{
                                            return(                                                
                                                <div key={index} className=''>
                                                    <div className='grid grid-cols-2 gap-4 content-between'>
                                                        <div>
                                                            <label className="text-xl" htmlFor="description">
                                                                Testcase{index + 1}
                                                            </label>
                                                        </ div>
                                                        <div className='text-right right-0 text-gray-400'>
                                                            <button 
                                                                type="button" 
                                                                onClick={() => {
                                                                    let temp = testcases
                                                                    temp.splice(index, 1)
                                                                    setTestcases(temp)
                                                                    handleClick();
                                                                }}
                                                                className="text-right"
                                                            > 
                                                            -Delete</button>
                                                        </div>                                                        
                                                    </div>  
                                                    <div className="mb-3">
                                                        <label className="text-lg" htmlFor="description">
                                                            Input:
                                                        </label>
                                                    </div> 
                                                    <textarea
                                                        className="p-2 rounded text-xl w-full text-black"
                                                        type="text"
                                                        label=""
                                                        value={testcases[index].Input}
                                                        onChange={(e) => {                                                            
                                                            let temp = testcases
                                                            temp[index].Input = e.target.value
                                                            setTestcases(temp);
                                                            handleClick();
                                                        }
                                                    } 
                                                        required                                 
                                                        placeholder="Input"              
                                                    />
                                                    <div className="mb-1">
                                                        <label className="text-lg" htmlFor="output">
                                                            Output:
                                                        </label>
                                                    </div> 
                                                    <textarea
                                                        className="p-2 rounded text-xl w-full text-black"
                                                        type="text"
                                                        label=""
                                                        value={testcases[index].Output}
                                                        onChange={(e) => {
                                                                let temp = testcases
                                                                temp[index].Output = e.target.value
                                                                handleClick();
                                                                setTestcases(temp)
                                                            }
                                                        }
                                                        required                                 
                                                        placeholder="Output"              
                                                    />
                                                    <div className="mb-1">
                                                        <label className="text-lg" htmlFor="visible">
                                                            Visible:
                                                        </label>
                                                    </div> 
                                                    <div className="mb-3">
                                                        <select 
                                                            name="visible"
                                                            className="p-2 rounded text-xl w-full text-black"
                                                            value={testcases[index].Visible}
                                                            onChange={(e) =>  {
                                                                let temp = testcases
                                                                temp[index].Visible = e.target.value
                                                                handleClick();
                                                                setTestcases(temp)
                                                            }}
                                                        >
                                                            <option value="false">false</option>
                                                            <option value="true">true</option>
                                                        </select>
                                                    </div>  
                                                    <hr className="my-5"></hr>
                                                </div>
                                                
                                            )
                                        })
                                    }
                                    <div className="text-center text-2xl">
                                        <button type="button" onClick={addTestcase}> Add TestCase +</button>
                                    </div>

                                    <div className="mb-4">
                                        <button
                                            type="submit" 
                                            onClick={handleSubmit}  
                                            className="p-2 bg-green-500 rounded-xl"                       
                                        >  
                                            Submit                              
                                        </button>
                                    </div>                                                                    
                                </form>                         
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
 
export default AddProblem;