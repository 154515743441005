import React from "react";
import { Link } from "react-router-dom";

const AssignmentModal = ({ setShowModal, id, title, info}) => {

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative" style={{width:"70vh"}}>
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                {title}
                            </h3>
                            <button
                                className=""
                                onClick={() => setShowModal(false)}
                            >
                                <span className="text-black text-2xl">
                                    X
                                </span>
                            </button>
                        </div>

                        <div className="relative flex-auto">
                            {info ?
                                <div className="p-5 max-w-[600px] overflow-auto">
                                    <div className="text-xl font-bold">Status:</div>
                                    <div className={info.status === 'Accepted' ? "text-green-500" : "text-red-500"}>{info.status}</div>
                                    <div className="text-xl font-bold">Language:</div>
                                    <pre>{info.language}</pre>
                                    <div className="text-xl font-bold">Code:</div>
                                    <pre className='rounded-xl bg-gray-100 p-5 max-h-[400px] overflow-auto'>{info.code}</pre>
                                    <div className="text-xl font-bold">Comment:</div>
                                    <pre >{info.comment ? info.comment :<span class="text-red-500">No Comment</span>}</pre>
                                    <div className="text-xl font-bold">Grade:</div>
                                    <pre>{info.grade !== -1 ? info.grade :<span class="text-red-500">Not Graded</span>}</pre>
                                </div>
                                :
                                <div className="p-5 text-xl font-bold">
                                    Not Submitted
                                </div>
                            }
                        </div>
                        <div className="flex justify-center p-4">
                            <Link 
                                to={`/assignments/?aid=${id}`} 
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded inline-block mt-4"
                            > 
                                Solve It!
                            </Link>
                        </div>
                        {/*footer*/}
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default AssignmentModal;
