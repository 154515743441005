export const fontSizeOptions = [
    {
      id: 1,
      name: "xs",
      label: "xs",
      value: 14,
    },
    {
        id: 2,
        name: "lg",
        label: "lg",
        value: 24,
    } ,
    {
        id: 3,
        name: "xl",
        label: "xl",
        value: 40,
    }  
  ];
  